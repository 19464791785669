<template>
  <div class="form__info">
    <p v-if="selectedServices.length > 1" class="price-discount">
      <del>Стоимость отчетов - {{ priceWithoutDiscount }}&nbsp;₽</del>
      <b>К оплате с учетом скидки - {{ price }}&nbsp;₽</b>
    </p>
    <p v-else class="price-discount">
      <b>Стоимость отчетов - {{ price }} ₽</b>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PriceWithDiscount',
  props: {
    selectedServices: {
      require: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
      type: Array
    },
    priceWithoutDiscount: {
      require: true,
      default: 0,
      type: Number
    },
    price: {
      require: true,
      default: 0,
      type: Number
    }
  }
};
</script>

<style lang="scss">
.price-discount {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (max-width: 640px) {
    gap: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }

  del {
    position: relative;
    margin-right: 15px;
    display: block;
    white-space: nowrap;
    text-decoration-thickness: 3px;
    text-decoration-color: #f14668;

    @media screen and (max-width: 480px) {
      white-space: normal;
    }
  }

  b {
    white-space: nowrap;
    display: block;

    @media screen and (max-width: 480px) {
      white-space: normal;
    }
  }

  @media screen and (max-width: 640px) {
    span {
      margin-right: 0;
    }

    b {
      margin: 0;
      margin-top: 15px;
      display: block;
    }
  }
}
</style>
