<template>
  <div class="form">
    <form class="form__address" novalidate @submit.prevent="submitSearch">
      <Address
        id="address"
        v-model="search"
        class="form__address-field"
        :error="error"
      />
      <Button :loader="isSearchRequest" type="submit">
        Найти объект
      </Button>
    </form>
    <span ref="nextStep"></span>
    <p v-if="info" class="form__info">
      {{ info }}
    </p>
    <Objects
      v-if="objects.length"
      v-model="object"
      title="Выберите кадастровый номер"
      :objects="objects"
    />
    <span ref="mapStep"></span>
    <SearchStaticMap v-if="object" :address="object.ADDRESS" />
    <template v-if="object">
      <p class="form__info">
        Информация по результату поиска
      </p>
      <ul class="form__order-info">
        <li>
          <span>Кадастровый номер:</span> <span>{{ object.CADNOMER }}</span>
        </li>
        <li>
          <span>Адрес:</span>
          <span class="address_dtl">{{ object.ADDRESS }}</span>
        </li>
        <li>
          <span>Тип:</span>
          <span :class="{ 'form__order-info-loader': !type }">{{ type }}</span>
        </li>
        <li>
          <span>Площадь:</span>
          <span :class="{ 'form__order-info-loader': !area }">{{ area }}</span>
        </li>
        <li v-if="object.OWN && object.OWN.length">
          <span>Количество собственников:</span>
          <span>
            {{ object.OWN.length }}
            <strong>Возможны ограничения!</strong>
          </span>
        </li>
        <li>
          <span>Категория:</span>
          <span :class="{ 'form__order-info-loader': !category }">{{
            category
          }}</span>
        </li>
      </ul>
      <p class="form__info">
        Выберете необходимые варианты отчетов
      </p>
      <div v-if="isObjectRequest" class="form__services">
        <div
          class="checkbox-detail"
          :class="{ 'checkbox-disabled': services.ARS }"
        >
          <Checkbox v-model="services.XZP" :disabled="services.ARS">
            Отчет об основных параметрах объекта недвижимости, в том числе
            проверка собственника (собственников), ограничение прав и
            обременения
          </Checkbox>
          <Button
            class="button button_small btn-detail"
            :disabled="services.ARS"
            @click.native="
              $store.commit('popup/open', {
                name: 'CheckboxDetailXZP',
                width: 900
              })
            "
          >
            Подробнее
          </Button>
        </div>
        <div
          class="checkbox-detail"
          :class="{ 'checkbox-disabled': services.ARS }"
        >
          <Checkbox v-model="services.SOPP" :disabled="services.ARS">
            Отчет об изменениях прав на объект недвижимости
          </Checkbox>
          <Button
            class="button button_small btn-detail"
            :disabled="services.ARS"
            @click.native="
              $store.commit('popup/open', {
                name: 'CheckboxDetailSOPP',
                width: 900
              })
            "
          >
            Подробнее
          </Button>
        </div>
      </div>
      <template v-if="price">
        <PriceWithDiscount
          :selected-services="selectedServices"
          :price="price"
          :price-without-discount="priceWithoutDiscount"
        />
        <div class="btns_order">
          <Button type="button" @click.native="goToCart">
            Перейти к оформлению заказа
          </Button>
          <Button secondary type="button" :loader="isOrderMore" @click.native="orderMore">
            Заказать еще
          </Button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import './form.scss';

import Address from '@/components/search/Address';
import Button from '@/components/gui/button/Button';
import Objects from '@/components/search/Objects';
import Checkbox from '@/components/gui/checkbox/Checkbox';
import PriceWithDiscount from '@/components/gui/priceWithDiscount/PriceWithDiscount';

import search from '@/api/search';
import searchByCode from '@/api/searchByCode';

const services = {
  KPT: false,
  XZP: false,
  SOPP: false,
  SKS: false,
  ARS: false
};

export default {
  name: 'Form',
  components: {
    Address,
    Button,
    Objects,
    Checkbox,
    PriceWithDiscount,
    SearchStaticMap: () => import('@/components/maps/SearchStaticMap')
  },
  data() {
    return {
      object: null,
      search: '',
      error: '',
      info: '',
      objects: [],
      isSearchRequest: false,
      isObjectRequest: false,
      services: Object.assign({}, services),
      isOrderMore: false
    };
  },
  computed: {
    cadQuarter() {
      return this.object !== null && this.object.TYPE == 'Кадастровый квартал';
    },
    selectedServices() {
      const arrTypes = [];

      for (const key in this.services) {
        if (Object.hasOwnProperty.call(this.services, key)) {
          this.services[key] && arrTypes.push(key);
        }
      }

      return arrTypes;
    },
    cost() {
      return this.$store.state.config.cost;
    },
    price() {
      return this.selectedServices.length > 1
        ? this.priceWithoutDiscount * 0.8
        : this.priceWithoutDiscount;
    },
    priceWithoutDiscount() {
      let price = 0;

      this.selectedServices.forEach(type => {
        price += Number(this.cost[type]);
      });

      return price;
    },
    type() {
      if (this.object.TYPE === undefined) {
        return undefined;
      } else {
        return this.object.TYPE || '-';
      }
    },
    area() {
      if (this.object.AREA === undefined) {
        return undefined;
      } else {
        return this.object.AREA || '-';
      }
    },
    category() {
      if (this.object.CATEGORY === undefined) {
        return undefined;
      } else {
        return this.object.CATEGORY || '-';
      }
    }
  },
  watch: {
    search() {
      this.error = '';
    },
    object(value, prevValue) {
      if (value === null) {
        return;
      }

      if (!prevValue) {
        this.$refs.mapStep.scrollIntoView();
      }

      // if (value.AREA === undefined) this.getObjectByCode(value.CADNOMER);
    },
    objects(value) {
      if (!value || !Object.keys(value).length) {
        return;
      }

      this.$refs.nextStep.scrollIntoView();
    },
    'services.ARS'(ARS) {
      if (ARS) {
        this.services.KPT = false;
        this.services.XZP = false;
        this.services.SOPP = false;
        this.services.SKS = false;
      }
    }
  },
  methods: {
    clearData() {
      this.object = null;
      this.search = '';
      this.error = '';
      this.info = '';
      this.objects = [];
      this.isSearchRequest = false;
      this.isObjectRequest = false;
      this.services = Object.assign({}, services);
      // this.isOrderMore = false;
    },
    checkOrderCart() {
      const checkCart = this.$store.state.cartData.find(
        data => data.object.CADNOMER === this.object.CADNOMER
      );

      if (checkCart === undefined) {
        this.addToCart();

        return true;
      } else {
        this.$store.commit('popup/open', {
          name: 'AddToCartWarning',
          width: 600
        });

        return false;
      }
    },
    addToCart() {
      this.$store.commit('addCartData', {
        object: this.object,
        services: this.services
      });
    },
    orderMore() {
      this.isOrderMore = true;
      const isUnique = this.checkOrderCart();

      if (isUnique) {
        this.clearData();

        setTimeout(() => {
          document.querySelector('#address input').focus();
          this.isOrderMore = false;
        }, 300);
      } else {
        this.isOrderMore = true;
      }
    },
    goToCart() {
      const isUnique = this.checkOrderCart();

      if (isUnique) {
        this.$router.push('/cart');
      }
    },
    submitSearch() {
      if (this.isSearchRequest) return;

      this.clearForm();

      if (this.search.split(':').length === 4)
        this.getObjectByCode(this.search);
      else this.getObjectByAddress();
    },
    clearForm() {
      this.object = null;
      this.objects = [];
      this.error = '';
      this.info = '';
    },
    async getObjectByCode(cad_number) {
      this.isSearchRequest = true;
      this.isObjectRequest = true;

      window.top.location='https://egrnzakaz.ru?search=2&data=' + cad_number;
      const { object, own, Success } = await searchByCode({
        cad_number
      });

      if (Success) {
        this.object = object;
        this.object.OWN = own;
      }

      this.isSearchRequest = false;
      this.isObjectRequest = false;
    },
    async getObjectByAddress() {
      this.isSearchRequest = true;

      const address = this.search.trim();

      if (!(address && address.replaceAll(':', '').trim())) {
        this.error = 'Укажите корректный адрес или кодастровый номер объекта';
        this.isSearchRequest = false;

        return;
      }

      window.top.location='https://egrnzakaz.ru?search=1&data=' + address;
      const { Success, objects, found, found_all } = await search({
        address
      });

      if (found === 1 && found_all) {
        this.getObjectByCode(objects[0].CADNOMER);
        this.object = objects[0];

        return;
      }

      if (Success && found > 0 && !found_all) {
        this.info =
          'Результат выдачи по данному запросу не полный. Если Вы не нашли нужную информацию, повторите поиск';
      } else if (Success && found === 0) {
        this.error = 'По данному запросу записей в Росреестре не найдено';
      } else if (!Success) {
        this.error =
          'Произошла ошибка при запросе в Росреестр. Повторите попытку еще раз';
      } else if (found > 0) {
        this.info = 'По данному адресу найдено несколько объектов недвижимости';
      }

      this.objects = objects || [];

      this.isSearchRequest = false;
    }
  }
};
</script>
