<template>
  <div class="main-page">
    <div class="main-page__bg-wrapper main-page__top">
      <span class="main-page__bg"></span>
      <div class="main-page__bg-content">
        <Header />
        <Title class="main-page__header-title">
          <template v-slot:subtitle>
            Независимый онлайн-проект по предоставлению<br />
            справок и отчетов населению
          </template>
        </Title>
        <h1 class="main-page__title">
          Отчет об объекте недвижимости онлайн
        </h1>
        <Form class="main-page__form" />
        <AdvantagesLine class="main-page__advantages-line" />
        <Title>
          Закажите актуальную информацию<br class="desktop tablet" />
          о любом объекте недвижимости в России
          <template v-slot:subtitle>
            без очередей и госпошлин
          </template>
        </Title>
        <Blocks class="main-page__blocks" />
        <Title>
          Как получить<br class="mobile" />
          отчет по данным из ЕГРН?
        </Title>
        <div class="main-page__steps">
          <Steps />
          <div class="main-page__steps-action">
            <Button @click.native="order">
              Заказать отчет
            </Button>
            <p>
              *На вашу электронную почту придет файл в формате PDF с информацией
              о настоящих<br />
              и предыдущих владельцев объекта с уточнением долей собственности.
            </p>
          </div>
        </div>
        <div class="main-page__container">
          <Title class="main-page__border-description" small>
            Что показывает отчет об объекте<br class="tablet" />
            недвижимости?
            <template v-slot:subtitle>
              Готовый отчет по данным официального источника ЕГРН, содержит
              актуальную информацию обо всех действиях с недвижимостью в РФ,
              начиная с 1998 года. В документе представлены сведения из базы на
              дату отправки запроса.
            </template>
          </Title>
        </div>
      </div>
    </div>
    <div class="main-page__bg-wrapper main-page__types">
      <span class="main-page__bg"></span>
      <div class="main-page__bg-content">
        <Title>
          Виды отчетов и стоимость
        </Title>
        <Types />
      </div>
    </div>
    <div class="main-page__container">
      <Discount class="main-page__discount" />
    </div>
    <div class="main-page__advantages">
      <Title>
        Почему мы?
      </Title>
      <Advantages />
    </div>
    <Guarantee class="main-page__guarantee" />
    <div class="main-page__partners">
      <Title small>
        Сервис работает при содействии
        <template v-slot:subtitle>
          Мы постоянно развиваем инструменты онлайн-сервиса при поддержке
          государственных<br />
          и муниципальных организаций. Мы сокращаем время формирования отчетов и
          добавляем новые функции.
        </template>
      </Title>
      <Partners class="main-page__partners-list" />
    </div>
    <div class="main-page__questions">
      <Title>
        Часто задаваемые вопросы
      </Title>
      <Questions />
    </div>
    <QuestionsForm class="main-page__questions-form" />
    <div class="main-page__description">
      <p>
        Заказ в режиме онлайн – самый удобный и быстрый способ получения
        информации об объекте недвижимости. Вам не придется ехать в МФЦ, платить госпошлину и ждать пять рабочих дней для получения документа. В
        режиме онлайн мы предоставляем информацию об объектах из любого региона
        страны напрямую из Росреестра и делаем это круглосуточно.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/uniq/header/Header';
import Button from '@/components/gui/button/Button';
import Form from '@/components/views/main/form/Form';

import './main-page.scss';

export default {
  name: 'Main',
  components: {
    Header,
    Button,
    Form,
    AdvantagesLine: () =>
      import('@/components/views/main/advantages-line/AdvantagesLine'),
    Steps: () => import('@/components/views/main/steps/Steps'),
    Types: () => import('@/components/views/main/types/Types'),
    Discount: () => import('@/components/views/main/discount/Discount'),
    Advantages: () => import('@/components/views/main/advantages/Advantages'),
    Guarantee: () => import('@/components/views/main/guarantee/Guarantee'),
    Partners: () => import('@/components/views/main/partners/Partners'),
    Questions: () => import('@/components/views/main/questions/Questions'),
    QuestionsForm: () =>
      import('@/components/views/main/questions-form/QuestionsForm'),
    Title: () => import('@/components/gui/title/Title'),
    // Telegram: () => import('@/components/views/main/telegram/Telegram'),
    Footer: () => import('@/components/uniq/footer/Footer'),
    Blocks: () => import('@/components/views/main/blocks/Blocks')
  },
  methods: {
    order() {
      document.querySelector('#address input').focus();
    }
  }
};
</script>
