<template>
  <Select
    ref="select"
    v-model="addressModel"
    items-key="value"
    :before-list="warning || beforeList"
    label="value"
    :show-loading="showLoading"
    :title="title"
    :error="error"
    :list="list"
    :mask="mask"
    @focus="focus"
    @input="getListTimeout"
    @listClick="clearList"
  />
</template>

<script>
import Select from '@/components/gui/select/Select';

import axios from 'axios';

const DADATA_URL = process.env.VUE_APP_DADATA_URL + 'address';
const TOKEN = process.env.VUE_APP_DADATA_TOKEN;

export default {
  name: 'AddressSearch',
  components: {
    Select
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: 'Адрес объекта или кадастровый номер'
    },
    model: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    to: {
      type: [String, Boolean],
      default: true
    }
  },
  data() {
    return {
      list: [],
      warning: '',
      timeoutID: null,
      showLoading: 0,
      isMounted: false,
      mask: ''
    };
  },
  computed: {
    beforeList() {
      if (!this.isMounted) return;

      if (!this.addressModel) {
        if (this.$refs.select && this.$refs.select.fieldModel) {
          return 'Выберите один из предложенных вариантов:';
        } else {
          return 'Начните писать и выберите один из предложенных вариантов';
        }
      }

      return '';
    },
    addressModel: {
      get() {
        return {
          value: this.model
        };
      },
      set(v) {
        this.$emit('change', v.value);
      }
    }
  },
  watch: {
    model() {
      this.list = [];
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    clearList() {
      this.list = [];
    },
    focus(v) {
      if (
        this.addressModel ||
        (!this.addressModel && this.list && this.list.length > 0)
      )
        return;

      this.getList(v);
    },
    getListTimeout(v) {
      this.$emit('change', v);
      this.warning = '';
      this.list = [];

      if (v.length < 3) {
        this.mask = '';
        return;
      } else if (v.length >= 3) {
        if (isNaN(+v.replaceAll(':', '').trim())) {
          this.mask = '';
        } else {
          this.mask = '##:##:######[(1):]#[(1):]######';

          return;
        }
      }

      this.showLoading = 5;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.getList(v), 600);
    },
    getList(v) {
      if (!v) {
        this.list = [];
        this.showLoading = 0;
        return;
      }

      const data = {
        count: 5,
        restrict_value: true,
        query: v
      };

      axios
        .post(DADATA_URL, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${TOKEN}`
          }
        })
        .finally(() => {
          this.showLoading = 0;
        })
        .then(response => {
          if (
            response.data &&
            response.data.suggestions &&
            response.data.suggestions.length > 0
          ) {
            this.list = response.data.suggestions;
          } else {
            this.list = [];
          }

          if (this.list.length === 0)
            this.warning = 'Нет вариантов удовлетворяющих запросу';
        });
    }
  }
};
</script>
