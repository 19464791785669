<template>
  <label class="checkbox" :class="classes">
    <span class="checkbox__element">
      <input v-model="value" type="checkbox" :disabled="disabled" />
      <span></span>
    </span>
    <span class="checkbox__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    isRound: {
      type: Boolean,
      default: false
    },
    model: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.model;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    classes() {
      return {
        checkbox_error: this.error
      };
    }
  }
};
</script>

<style lang="scss">
@import 'checkbox';
</style>
