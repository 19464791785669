<template>
  <div>
    <Select
      ref="select"
      v-model="object"
      :list="objects"
      label="ADDRESS"
      items-key="ADDRESS"
      :close-after-choose="true"
      :before-item="item => item.CADNOMER"
      :title="title"
      :readonly="true"
      @keydown.delete.native="deleteEvent"
    />
  </div>
</template>

<script>
import Select from '@/components/gui/select/Select';

export default {
  name: 'FindObjects',
  components: {
    Select
  },
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    objects: {
      type: Array
    },
    title: {
      type: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object
    }
  },
  computed: {
    object: {
      set(v) {
        this.$emit('change', v);
      },
      get() {
        return this.model;
      }
    }
  },
  methods: {
    deleteEvent() {
      this.object = null;
    }
  }
};
</script>
